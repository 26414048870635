import "./App.css";
import React, {useState, useEffect} from "react";
import {Navbar} from ".//Navbar/navbar.jsx";
import {Footbar} from './/Footbar/footbar.jsx';
import { emailSent, projects } from "./script.js";
import cv from "./CV.pdf";



export default function App() { 
    const navLinks = document.querySelectorAll(".nav-link");
    const section = document.querySelectorAll("section");
    let currentSection = "home";
    window.addEventListener("scroll", () => {
      section.forEach(sectionel => {
        if (window.scrollY >= (sectionel.offsetTop) - sectionel.clientHeight / 3) {
          currentSection = sectionel.id;
        }
        navLinks.forEach(navlinkel => {
          if (navlinkel.firstChild.href.includes(currentSection)) {
            navlinkel.classList.add("active");
            
          }
          else {navlinkel.classList.remove("active")}
        })
      })
    })
  
  const [project, setProjects] = useState([]);
  useEffect(() => {
    setProjects(projects)
  }, []);
  return (
    <>
    <Navbar className="nav" />
    <main id="container">
      <section className="home" id="home">
          <div className="col-1">
            <h1 className="intro-text">Hi, I'm</h1>
            <h1 className="intro-title">Emiliano <span className="intro-accent">Avvisato</span></h1>
            <h1 className="intro-text">A <span className="intro-accent">web developer</span></h1>
            <q>The computer was born to solve problems that did not exist before.<cite> (B. Gates)</cite></q>
            <a className="d-button" href={cv} download>Download CV</a>
            <a href="#proj" className="intro-link">Projects</a>
          </div>
          <div className="col-2">
            <img src="https://picsum.photos/400/400"  alt="" className="my-photo"></img>
            <ul>
              <li className="skill-card">+ <p>Attent to detail</p></li>
              <li className="skill-card">+ <p>Team oriented</p></li>
              <li className="skill-card">+ <p>Responsive design</p></li>
            </ul>
          </div>
        </section>
      <section className="about-me" id="am">
        <p className="sub-title">My uniqueness</p>
        <h2 className="page-title">About me</h2>
        <div className="am-container">
          <div className="am-col-1">
            <img src="https://picsum.photos/250/200" alt="" className="am-image front"></img>
            <img src="https://picsum.photos/250/200" alt="" className="am-image retro"></img>
          </div>
          <div className="am-col-2">
            <p className="bio"><span className="accent">Currently</span> a university student in the computer science department at the University of Pisa, I consider myself a passionate web developer with advanced skills in various programming languages. I am fluent in English, Italian, and Spanish, allowing me to effectively communicate in diverse work situations.

My practical experience includes in-depth knowledge of languages such as JavaScript, HTML, CSS, Node.js, jQuery, and React, enabling me to create dynamic and interactive websites. I also have a strong understanding of responsive design and user experience concepts, helping me to create intuitive and engaging user experiences.

I am a creative and detail-oriented individual, always looking for new challenges and opportunities for professional growth in the field of web development. I am able to work independently, but I also integrate well into multidisciplinary teams. I am motivated and determined to constantly improve my skills and stay up to date with the latest trends and technologies. I am excited to put my knowledge and skills into practice to contribute to your company's success in the digital field.</p>
          <div className="cont-skills">
            <ul><p>Skills</p>
              <li className="skills"><img src="https://img.icons8.com/?size=25&id=20909&format=png&color=000000" alt="Html" /> <img src="https://img.icons8.com/?size=25&id=21278&format=png&color=000000" alt="Css" /></li>
              <li className="skills"><img src="https://img.icons8.com/?size=25&id=108784&format=png&color=000000" alt="Javascript" /> <img src="https://img.icons8.com/?size=25&id=nCj4PvnCO0tZ&format=png&color=000000" alt="Typescrip"></img></li>
              <li className="skills"><img src="https://img.icons8.com/?size=25&id=40253&format=png&color=D1B307" alt="Jquery" /><img src="https://img.icons8.com/?size=25&id=hsPbhkOH4FMe&format=png&color=000000" alt="Node.js" /></li>
              <li className="skills"><img src="https://img.icons8.com/?size=25&id=123603&format=png&color=000000" alt="React" /><img src="https://img.icons8.com/?size=22&id=kg46nzoJrmTR&format=png&color=E9EDF1" className="express" alt="Express.js" /></li>
              <li className="skills"><img src="https://img.icons8.com/?size=25&id=bosfpvRzNOG8&format=png&color=000000" alt="Mongodb" /><img src="https://img.icons8.com/?size=25&id=62452&format=png&color=000000" alt="Firebase" /></li>
            </ul>
            <ul><p>Soft skills</p>
              <li className="s-skills">Comunication</li>
              <li className="s-skills">Detail oriented</li>
              <li className="s-skills">Team-work</li>
              <li className="s-skills">Creativity</li>
            </ul>
           </div>
          </div>
        </div>
      </section>
        <section className="proj" id="proj">
          <p className="sub-title">Work in progress...</p>
          <h2 className="page-title">Projects</h2>
          <ul className="proj-grid">
            {project.map(t =>
                  // <li className="proj-card" style={{background: `url(${t["img"]})`, backgroundSize: "auto 250px", backgroundRepeat: "no-repeat", backgroundPositionX: "50%"}}>
                  //   <div className="overlay">
                  //     <p className="card-text">{t["text"]}</p>
                  //     <div className="button-space">
                  //       <a href={t["demo"]} className="demo">Demo live</a>
                  //       <a href={t["code"]} className="code">Code</a>
                  //     </div>
                  //   </div>
                  // </li>
                  <li className="proj-card">
                    <img src={t["img"]} alt="" className="proj-img"/>
                    <div className="proj-description">
                      <a className="proj-title" href={t["demo"]}>{t["title"]}</a>
                      <p className="proj-text">{t["text"]}</p>
                      <a className="proj-button" href={t["code"]}>Code</a>
                    </div>
                  </li>
                )}
          </ul>
        </section>
        <section className="contact" id="cont">
          <p className="sub-title">Get in touch</p>
          <h2 className="page-title">Contact me</h2>
          <div id="con">
            <div className="buttons">
              <div className="button">
                <ion-icon name="mail-outline" className="icon"></ion-icon>
                <h3 className="title">Mail</h3>
                <p className="l-text">emiliano.avvisato@gmail.com</p>
                <a href="mailto:emiliano.avvisato@gmail.com">Send a message</a>
              </div>
              <div className="button">
                <ion-icon name="logo-whatsapp" className="icon"></ion-icon>
                <h3 className="title">Whatsapp</h3>
                <p className="l-text">emi-avvisato</p>
                <a href="https://wa.me/3921075985">Send a message</a>
              </div>
              <div className="button">
                <ion-icon name="logo-linkedin" className="icon"></ion-icon>
                <h3 className="title">Linkedin</h3>
                <p className="l-text">emiliano.avvisato@gmail.com</p>
                <a href="https://www.linkedin.com/in/emiliano-avvisato-4a9a001b1/" target="blank">Send a message</a>
              </div>
            </div>
            <div className="inputs-con">
              <form id="form" className="input" action="https://formsubmit.co/emiliano.avvisato@gmail.com" method="POST" onSubmit={emailSent}>
                <input id="name" type="text" placeholder="Your name" name="name" required/>
                <input id="mail" type="email" placeholder="Your mail" name="email" required/>
                <input id="message" type="text" placeholder="Message" name="msg" required/>
                <input type="hidden" name="_captcha" value="false"></input>
                <button className="send" id="send"type="submit">Send message</button>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footbar />
    </>
    
  )
}
