import React from "react";
import "./navbar.css";

const Navbar = () => {
  return (
    <nav className="nav">
      {/* <div class="burger">
        <div id="line-one"></div>
        <div id="line-two"></div>
        <div id="line-three"></div>
      </div> */}
      
      <ul className="nav-links">
        <li className="nav-link"><a className="ac"href="#home">
        <ion-icon name="home-outline" class="nav-icons"></ion-icon>
          <p class="nav-texts">| Home</p></a></li>
        <li className="nav-link"><a href="#am">
        <ion-icon name="accessibility-outline" class="nav-icons"></ion-icon>
          <p class="nav-texts">| About me</p></a></li>
        <li className="nav-link"><a href="#proj">
        <ion-icon name="terminal-outline" class="nav-icons"></ion-icon>
          <p class="nav-texts">| Projects</p></a></li>
        <li className="nav-link"><a href="#cont">
        <ion-icon name="paper-plane-outline" class="nav-icons"></ion-icon>
          <p class="nav-texts">| Contact me</p></a></li>
      </ul>
    </nav>
  )
}

export {Navbar};