import "./footbar.css";

const Footbar = () => {
  return (
    <footer>
      <div className="waves">
        <span className="wave" id="wave1"></span>
        <span className="wave" id="wave2"></span>
        <span className="wave" id="wave3"></span>
        <span className="wave" id="wave4"></span>
      </div>
      <ul className="social">
        <li><a href="https://wa.me/3921075985"><ion-icon name="logo-whatsapp"></ion-icon></a></li>
        <li><a href="https://www.linkedin.com/in/emiliano-avvisato-4a9a001b1/"><ion-icon name="logo-linkedin"></ion-icon></a></li>
        <li><a href="https://github.com/Emi-02"><ion-icon name="logo-github"></ion-icon></a></li>
      </ul>
      <ul className="menu-footer">
        <li><a href="#home">Home</a></li>
        <li><a href="#am">About</a></li>
        <li><a href="#proj">Projects</a></li>
        <li><a href="#cont">Contact</a></li>
      </ul>
      <p>All Rights are reserved ©</p>
    </footer>
  )
}

export {Footbar};