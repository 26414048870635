import  Swal from "sweetalert2";  
import tWeather from "./img/todayWeather.png";
import todo from "./img/toDoList.png";
import wProgress from "./img/wProgress.png"
export var projects= [{
  "img": wProgress,
  "title": "IBuy",
  "text": "IBuy is a modern e-commerce page example that uses Stripe to manage payments" ,
  "demo": "demo",
  "code": "repository"
},
{
  "img": tWeather,
  "title": "Worldforecast",
  "text": "Worldweather is a forecast app that uses Openweathermap API to retrieve weather information about countries that you search for. Made with React.js, Javascript, Openweathermap API and Axios  " ,
  "demo": "https://66b8febe1c5cc2950b094e11--worldforecast.netlify.app/",
  "code": "https://github.com/Emi-02/Todayweather"
},
{
  "img": todo,
  "title": "Loc-todolist",
  "text": "Loc-todolist is a minimal tasks app that utilize the local storage to save you to-do list. Made with Javascript, Html, Css and Jquery" ,
  "demo": "https://loc-todolist.netlify.app/",
  "code": "https://github.com/Emi-02/To-do-app-1.git"
}]


export function emailSent(){
  //   var name= document.getElementById("name").value;
  // var mail= document.getElementById("mail").value;
  // var message= document.getElementById("message").value;
  return (
    Swal.fire({
      title: "Success",
      text: "Your mail was successfully delivered",
      icon: "success"
    }))
  }
